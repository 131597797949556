export const posts = [
  {
    id: 'post1',
    title: 'Traveling Japan and Korea',
    subheader:
      'I spent one month traveling East Asia with friends from the University of Kansas.',
    author: 'Jet Semrick',
    date: '07-18-2023',
  },
]
